<template>
  <slide-y-up-transition :delay="300">
    <v-col cols="12" class="mt-3" v-if="display">
      <sheet depressed dense light outlined>
        <referenced-table />
      </sheet>
    </v-col>
  </slide-y-up-transition>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import { SlideYUpTransition } from "vue2-transitions";
import ReferencedTable from "@/modules/invoices/components/referenced/ReferencedTable.vue";
import { InvoiceData } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { ReferencedTable, SlideYUpTransition },
})
export default class InvoiceReferenced extends Mixins(InvoiceMixin) {
  get referenced(): InvoiceData[] {
    return this.obInvoice.get("referenced", []);
  }

  get display() {
    return this.referenced.length > 0;
  }
}
</script>

<style scoped></style>
