<template>
  <v-data-table
    dense
    :items="items"
    :headers="headers"
    disable-sort
    disable-pagination
    hide-default-footer
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('invoice.referenced')" />
      </v-toolbar>
    </template>

    <template #[`item.created_at`]="{ item }">
      <span>
        {{ $dayjs(item.created_at).format("L LT") }}
      </span>
    </template>

    <template #[`item.invoice_type`]="{ item }">
      <v-btn
        text
        color="primary"
        :to="{ name: 'invoices.update', params: { id: item.id } }"
      >
        {{ item.invoice_type.name }}
      </v-btn>
    </template>

    <template #[`item.total_price_value`]="{ item }">
      <price-viewer
        :value="item.total_price_value"
        :currency="item.currency"
        chip
        :label="true"
      />
    </template>

    <template #[`item.order_number`]="{ item }">
      <template v-if="item.is_signed">
        <span class="font-weight-bold" v-text="item.order_serial" />
        <span class="ml-2" v-text="item.order_number" />
      </template>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import { Invoice, InvoiceData } from "@planetadeleste/vue-mc-gw";

import PriceViewer from "@/components/common/PriceViewer.vue";

@Component({
  components: { PriceViewer },
})
export default class ReferencedTable extends Mixins(
  InvoiceMixin,
  DataTableMixin
) {
  mounted() {
    this.$nextTick(() => {
      const arHeaders: DataTableHeader[] = [
        { text: "date", value: "created_at" },
        {
          text: "invoice.type",
          value: "invoice_type",
        },
        {
          text: "invoice.dgi.number",
          value: "order_number",
        },
        { text: "total.invoice", value: "total_price_value" },
      ];
      this.setDTHeaders(arHeaders);
    });
  }

  get referenced(): InvoiceData[] {
    return this.obInvoice.get("referenced", []);
  }

  get items() {
    return this.$_.map(this.referenced, (obReference) =>
      obReference instanceof Invoice ? obReference.attributes : obReference
    );
  }
}
</script>
